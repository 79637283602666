@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

html {
  transition: 0.3s ease;
}

.about_container {
  padding: 90px 0;
}
.about_heading {
  text-align: center;
  /* padding: 20px 0px; */
}
.about_heading h2 {
  font-size: 40px;
  font-weight: 700;
}
.about_heading span {
  color: #dc143c;
}

.about_btn {
  text-align: center;
  gap: 30px;
  padding: 50px 0;
}
.about_btn button {
  padding: 5px 15px;
  margin: 10px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  background-color: #edf2f8;
  transition: box-shadow 0.2s ease, border-color 0.2s ease;
  border: 3px solid transparent;
}
.about_btn button:hover {
  background-color: #e4002b;
  color: #fff;
}
.about_btn button:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.2) 0px 5px 8px -3px;
  border-color: #03a9f4;
}
.about_btn button.selected {
  background-color: #e4002b;
  color: #fff;
}

.about_all {
  width: 90%;
  margin: auto;
}

.about_box1 {
  height: auto;
  width: 90%;
}
.about_box2 {
  width: 90%;
}

.about_box2 li {
  margin-top: 20px;
  font-size: 18px;
  /* font-weight: 700; */
}

.box1_gif {
  height: 100%;
  width: 100%;
}

.experience {
  margin-top: 30px;
  padding: 10px;
}

.me {
  font-size: 18px;
  font-weight: 500;
}

.experience_text {
  font-size: 18px;
  font-weight: 500;
}

/* Css For Experience */

.exp-company {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  color: #e4002b;
}

.exp-date {
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.exp-project {
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}
