* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.github {
  width: 100%;

  margin: 0 0 50px 0;
}
.cal {
  width: 80%;
  padding: 50px 20px;
  margin: auto;
  box-shadow: rgba(230, 17, 166, 0.19) 0px 10px 20px,
    rgba(70, 14, 202, 0.23) 0px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.github_text {
  text-align: center;
  padding: 10px;
}

.github_text h2 {
  font-size: 40px;
  font-weight: 700;
}
.github_text span {
  color: #e4002b;
}
