* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.star_header {
  text-align: center;
  padding: 20px 0;
}

.star_header h2 {
  font-size: 40px;
  font-weight: 700;
}
.star_header span {
  color: #e4002b;
}

.stars_container {
  width: 100%;
  padding: 20px 0;
  background-color: #edf2f8;
  margin-bottom: 30px;
}

.main_box {
  width: 80%;
  margin: auto;
  padding: 30px 0;
}

.box1 {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.box2 {
  display: flex;
  justify-content: center;
}
